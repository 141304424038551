import { Component, h, Host, Prop } from '@stencil/core';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZBreadcrumbConfig } from './types/ptz-breadcrumb.enums';
import { PTZBreadcrumbTypes } from './types/ptz-breadcrumb.types';

@Component({
  tag: 'ptz-breadcrumb',
  styleUrl: 'ptz-breadcrumb.scss',
  shadow: false
})

export class PTZBreadcrumb {
  /** Define se vai utilizar estilos da Petz ou de outra marca */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz;

  /** Aria label para o breadcrumb */
  @Prop() breadcrumbAriaLabel?: string;

  /** Tamanho do breadcrumb */
  @Prop() size: PTZBreadcrumbTypes.Size = PTZBreadcrumbConfig.Size.Large;

  render() {
    return (
      <Host>
        <nav
          aria-label={this.breadcrumbAriaLabel}
          class={`${this.brand}-breadcrumb ${this.brand}-breadcrumb-${this.size}`}
        >
          <slot />
        </nav>
      </Host>
    );
  }
}

ptz-breadcrumb {
  .ptz-breadcrumb {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;

    &-large {
      ptz-breadcrumb-item {
        span {
          font-size: $petz-font-size-2xs;
        }
      }
    }

    &-small {
      ptz-breadcrumb-item {
        span {
          font-size: $petz-font-size-3xs;
        }
      }
    }

    ptz-breadcrumb-item:last-child {
      ptz-icon {
        display: none;
      }
    }
  }

  .srs-breadcrumb {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;

    &-large {
      ptz-breadcrumb-item {
        span {
          font-size: $seres-font-size-2xs;
        }
      }
    }

    &-small {
      ptz-breadcrumb-item {
        span {
          font-size: $seres-font-size-3xs;
        }
      }
    }

    ptz-breadcrumb-item:last-child {
      ptz-icon {
        display: none;
      }
    }
  }
}
